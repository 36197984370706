import React, { Suspense } from 'react';
import CustomContainer from './styles';
import Loading from '../Loading';

const renderLoader = () => Loading;

export default function Container({ children, ...rest }) {
	return (
		<Suspense fallback={renderLoader()}>
			<CustomContainer {...rest}>{children}</CustomContainer>
		</Suspense>
	);
}
