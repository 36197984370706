import styled from 'styled-components';

const Container = styled.div`
	background-color: ${(props) => (props.backgroundcolor ? `${props.backgroundcolor}` : '#fff')};
	min-height: 100%;

	@media (max-width: 767px) {
		width: 100%;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	@media (min-width: 768px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
`;

export default Container;
