import api from '../../services/api';

export const Types = {
	VALIDATE_COUPON: 'VALIDATE_COUPON',
	GET_USED_COUPON: 'GET_USED_COUPON',
	GET_USED_PROMOTIONAL_CODE: 'GET_USED_PROMOTIONAL_CODE',
};

const INITIAL_STATE = {};

export function coupons(state = INITIAL_STATE, action) {
	switch (action.type) {
		case Types.VALIDATE_COUPON:
			return {
				...state,
				validatedCoupon: action.validatedCoupon,
			};
		case Types.GET_USED_COUPON:
			return {
				...state,
				getUsedCoupon: action.getUsedCoupon,
			};

		case Types.GET_USED_PROMOTIONAL_CODE:
			return {
				...state,
				getUsedPromotionalCode: action.getUsedPromotionalCode,
			};

		default:
			return state;
	}
}

export const Creators = {
	validateCoupon: (id) => async (dispatch) => {
		return api
			.get(`/api/coupons/${id}`)
			.then((response) =>
				dispatch({
					type: Types.VALIDATE_COUPON,
					validatedCoupon: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
	getUsedCoupon: (uuid_billing) => async (dispatch) => {
		return api
			.get(`/api/coupons/used/${uuid_billing}`)
			.then((response) =>
				dispatch({
					type: Types.GET_USED_COUPON,
					getUsedCoupon: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},

	getUsedPromotionalCode: (uuid_billing) => async (dispatch) => {
		return api
			.get(`/api/coupons/promotional/used/${uuid_billing}`)
			.then((response) =>
				dispatch({
					type: Types.GET_USED_PROMOTIONAL_CODE,
					getUsedPromotionalCode: response.data,
				})
			)
			.catch((err) => Promise.reject(err.response));
	},
};
